@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500;1,600;1,700&family=Ubuntu:wght@700&display=swap');
#root:before {
    top: 0;
}
#root:after {
    bottom: 0;
}
#root {
    border-left: .5em #ffcc00 solid;
    border-right: .5em #ffcc00 solid;
    /* background: #023047;  */
}
body{
    font-family: 'Josefin Sans', sans-serif;
    color:white;
    background: #023047; 
}
h1  {
    font-family: 'Josefin Sans', sans-serif;
    line-height: 1.28571429em;
    margin: calc(2rem - .14285714em) 0 1rem;
    font-weight: 700;
    padding: 0;
}
a {
    color: #3399cc;
    text-decoration: none;
}

.ui.segment {
    font-size: 1.25rem;
    background-color: transparent;
}
.ui.menu {
    background: #8ECAE6;
    font-size: 1.1rem;
    font-weight: 900;
    
}
.sixteen.wide.column.category{
    padding-left: 0px;
    padding-right: 0px;
}
.sixteen.wide.column.algo{
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 0px;
}
.ui.container.grid.algo {
    padding-left: 0px;
    margin-left: 0px;
}


.ui.menu .item{
    color:white;
}
.ui.menu .active.item {
    background: #8ECAE6;
    font-size: 1rem;
    color:white;
    font-weight: bold;
}

.ui.menu.vertical{
    background: #8ECAE6;
    width: auto;
}
.ui.menu.vertical.questionList.dropDown {
    position: absolute;
    z-index: 100;
    border: 1px solid rgba(0, 0, 0, 0.281);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.397);
}
.ui.vertical.menu .active.item {
    background: #fb8500;
    color:rgb(255, 255, 255);
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ui.segment.question{
    border-radius: 5px;
    margin-top: 0px;
}
.ui.stackable.two.column.grid {
    padding: 0px;
}
.thirteen.wide.two.column.grid.question {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.sixteen.wide.two.column.grid.question {
    padding-top: 0px;
    padding-left: 0px;
}
.three.wide.stackable.two.column.grid {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.ui.segment.code {
    padding: 0px;
    margin: 0px;
}
.five.wide.column.solutions {
    margin-top: 0.5em;
    min-height: 150px;
}
.ui.pointing.menu.algo {
    margin: 0;
    color:white;
}
.row.solutions {
    border-top: 2px solid rgb(33 158 188);
    padding-top: .9em;
    padding-bottom: .5em;
}
.right.menu {
    padding: 0em;
    line-height: .5em;
}
.ui.text.menu{
    margin: 0;
}
.ui.text.menu .header.item {
    color:rgba(255, 255, 255, 0.555);

}
.ui.text.menu .item {
    color:rgba(255, 255, 255, 0.555);
    font-size: .9rem;
    font-weight: 500;
    padding-left: 0em;
    padding-right: 0.7em;
}
.ui.text.menu.solutions {
    margin: 0;
    padding-bottom: 1.3em;   
}
.ui.text.menu .active.item{
    color:rgba(255, 255, 255, 0.753);
    font-weight: 700;
    text-decoration: underline;
}
.questionDisplay {
    margin-bottom: 1em;
}
.codeDisplay pre {
    min-height: 450px;
}
@media only screen and (max-width: 900px) {
    #root {
        border: none;
    }
    .ui.segment.question{
        padding-right: .5em;
    }

}
@media only screen and (max-width: 768px) {
    #root {
        border: none;
    }
    .ui.container.grid{
        margin-left: 2em!important;
    }
    .ui.container.grid.algo{
        clear:both;
        margin-left: 2em!important;
    }
    .ui.vertical.menu.questionList{
        width: auto!important;
        margin-bottom: 3em!important;
        margin-right: 1em;
    }
    .ui.vertical.menu.algo{
        margin-top: 5em!important;
        margin-right: 1em;
        width: auto!important;
    }
    .three.wide.stackable.two.column.grid {
        margin-left: 1em!important;
    }
}

