
input, textarea {
	font-family:inherit;
	font-size: inherit;
}
input[type=text],input[type=email] {
	width:100%;
	background: #ffefb1;
	padding: 1em;
	margin: 1em 0em;
	border: 1px solid rgba(34,36,38,.15);
	box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
	border-radius:10px;
}
textarea {
	background: #ffefb1;
	width:100%;
	resize: vertical;
	padding:1em;
	margin: 1em 0em;;
	border-radius:15px;
	border: 1px solid rgba(34,36,38,.15);
	box-shadow:4px 4px 10px rgba(0,0,0,0.06);
	height:150px;
	transition: .3s box-shadow;
}
label {
	position: relative; /* to contain absolute elements */
	padding-right:5em;
	cursor: pointer;
}

.contact button {

	/* remove default behavior */
	appearance:none;
	-webkit-appearance:none;
	/* usual styles */
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.2em;
    font-weight: 700;
    z-index: 101;
    padding: .5em .5em;
    color: #fffffff0;
    background: #ffcc00;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    float: right;
	margin-bottom: 1em;
	
  }
.column.contact h3{
	position: relative;
    padding-left: .8em;
    color: #ffcc00;
    font-family: inherit;
    padding-top: 1em;
}

.error-message {
	font-family: inherit;
	font-size: inherit;
	/* box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
	border: 1px solid rgba(34,36,38,.15);
	border-radius: .28571429rem;
	background: #f8f8f9; */
    color: #fb8500;
	position: relative;
	width: 90%;
    margin: .1em;
    padding: 1em;
    line-height: 1.4285em;
}

.submit-feedback {
	font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4em;
	color: #fb8500;
	background: #000000;
}
