.ui.grid.headerPart {
    width: 100%;
    padding: 1em;
}
.logoLink {
    padding-top: .3em;

}
.logoLink h1{
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    font-size: 4em;
    text-align: left;
    letter-spacing: -5px;
}
.logoLink .name-logo{
    color: #3399cc;
}
.logoLink .name-short-logo{
    color: #3399cc;
    display: none;
}
.logoLink .domain-logo{
    color:#ffcc00;
}
.burgerMenu {
    position: fixed;
    z-index: 150;
    margin: 2em 2em;
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
    background-color: rgba(0, 0, 0, 0.877);
    border: 1px solid rgb(0, 0, 0, 0.877);
}

.contactButton {
    height: 2.5em;
    top: .7em;
    right: 4em;
    margin-bottom: 1.2em; 
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1.2em;
    z-index: 101;
    padding: 0em 1em;
    color: #fffffff0;
    background: #ffcc00;
    border: 1px solid rgb(34, 36, 38);
    border-radius: .38571429rem;
    box-shadow: 0 2px 2px 0 rgb(34 36 38 / 15%);
    position: relative;
}

.contactButton a {
    color: inherit;
}
.contactButton a:hover {
    color: #3399cc;
}
.nav-bar {
    position: fixed;
    z-index: 101;
    top: 1.3em;
    right: 1.4em;
    left: auto;
    bottom: auto;


    background: #000000;
    width: auto;
    font-size: 1.3rem;
    line-height: 1.6em;
    font-weight: 700;

    font-family: inherit;
    font-weight: 700;
    -webkit-box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    border-radius: .28571429rem;
    min-height: 2.85714286em;
}
.nav-bar ul {
    background: #000000;
    list-style-type: none;
    padding: 0;
}

.nav-bar ul li {
    /* background: #3399cc; */
    padding-left: .5em;
    padding-right: 1em;
    margin: 15px 15px 5px 5px;
}
.hide-arrow{
    display: none;
}
a:hover .hide-arrow{
    display: inline;
}
a:hover .space{
    display: hide;
}
@media only screen and (max-width: 768px) {
    #root {
        border: none;
    }
    .logoLink h1{
        
        font-size: 2.5em;
        text-align: left;
        letter-spacing: -2px;
    }
    .contactButton {
        top: .9em;
        font-size: inherit;
    }
}
@media only screen and (max-width: 481px) {
    .logoLink .name-logo{
        display: none;
    }
    .logoLink .name-short-logo{
        display: inline;
    }
}