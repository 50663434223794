.ui.grid.mainPart {
    margin-top: 3em;
}

.heading {
    width: 392px;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.half-a-border-on-top {
    /* border-bottom: .5em solid #ffcc00; */
    position: relative;
}
.half-a-border-on-top:after {
    padding:0;margin:0;display:block;/* probably not really needed? */
    content: "";
    height:73.5%;
    width:.5em;/* slight higher to work around rounding errors(?) on some zoom levels in some browsers. */
    background-color: #ffcc00;
    position: absolute;
    right:0;
    top:60%;
}
.half-border-on-bottom:after {
    padding:0;margin:0;display:block;/* probably not really needed? */
    content: "";
    width:50%;
    height:.5em;/* slight higher to work around rounding errors(?) on some zoom levels in some browsers. */
    background-color: #ffcc00;
    position: absolute;
    right:50%;
    bottom:0%;
}

.portraitDisplay {
    display: inline-block;
    position: relative;
    width: 392px;
    height: 390px;
    overflow: hidden;
    border-radius: 50%;
    padding-left: 0;
    padding-right: 0;
    border: #ffcc00 .5em solid;
    box-shadow: 0 0 5px 0px #ffcc00 inset, 0 0 0px 0px rgba(0, 0, 0, 0.418);
    margin-right: auto;
    margin-left:-.60em;
}

.portraitDisplay img {
    width: auto;
    height: 100%;
    margin-right: 30px;
}

.four.column.row.spacing.main {
    height: 300px;
}
.four.column.row.spacing.footer {
    height: 100px;
    
    width: 100%;
}
.four.column.row.spacing.projects {
    height: 100px;
    background: #ffcc00;
    margin-bottom: 2em;
}

.arrowMenu {
    height: 3em;
    position: fixed;
    z-index: 2001;
    display: inline-block;
    /* border-radius: 50%; */
    /* box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.418); */
    /* background-color: #ffcc00; */
    bottom: .5em;
    left: .5em;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
/* .arrowMenu img{
    bottom: 10px;
    left: .5em;
    position: fixed;
    box-shadow: 3px 3px 5px 6px #ccc;
} */

/*.arrow-second{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    font-size: 4em;
    text-align: left;
    letter-spacing: -5px;
    bottom: 5%;
    position: fixed;
    z-index: 2001;
    text-shadow: -1px 2px #000000e6;
    transform: rotate(90deg);
    color: #ffcc00;
} */
.arrow-second {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    font-size: 3em;
    letter-spacing: -3px;
    bottom: .65em;
    left: .1em;
    /* left: .5em; */
    position: fixed;
    z-index: 2001;
    text-shadow: -1px 2px #000000;
    transform: rotate(90deg);
    color: #ffcc00;
}
/*
    animation: pulse 1s 1;
  }
  
  @keyframes pulse {
    0% {
        bottom: 20%;
    }
    50% {
        bottom: 10%;
    }
    100% {
        bottom: 5%;
    }


.left-arrow:after {
    padding:0;margin:0;display:block;
    content: "";
    height:1080%;
    width:.5em;
    background-color: #ffcc00;
    position: absolute;
    right:0%;
    top:0;
} */
.heading h1{
    font-size: 4em;
    text-align: left;
    text-justify: auto;
    letter-spacing: -2px;
}
.shortH h1 {
    letter-spacing: -4px;
}
.yellowArrow{
    color : #ffcc00;
}

@media only screen and (max-width: 900px) {
    .heading {
        width: 360px;
        margin-left: auto;
        margin-right: auto;
        padding-left: .5em;
    }
    .portraitDisplay {
        margin-top: 3em;
        margin-left: auto;
        margin-right: auto;
    }


    .heading {
        margin-left: auto;
        margin-right: auto;
        padding-left: .5em;
    }
    .portraitDisplay {
        width: 360px;
        height: 365px;
        margin-top: 3em;
        margin-left: auto;
        margin-right: auto;
    }
    .half-border-on-bottom:after {
        height:0;
    }
    .half-a-border-on-top:after {
        width:0;
    }
    .four.column.row.spacing.main {
        height: 100px;
        margin-left: 10px;
        border-left: none;
    }
    
    .four.column.row.spacing.projects {
        height: 50px;
        border-left: none;

    }
    .heading h1{
        font-size: 3.5em;
        text-align: left;
        text-justify: auto;
        letter-spacing: -2px;
    }
}

@media only screen and (max-width: 481px) {
    .portraitDisplay {
        width: 300px;
        height: 305px;
    }
    .heading {
        width: 300px;
    }
    .heading h1{
        font-size: 3em;
    }
}