@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500;1,600;1,700&family=Ubuntu:wght@700&display=swap);
#root:before {
    top: 0;
}
#root:after {
    bottom: 0;
}
#root {
    border-left: .5em #ffcc00 solid;
    border-right: .5em #ffcc00 solid;
    /* background: #023047;  */
}
body{
    font-family: 'Josefin Sans', sans-serif;
    color:white;
    background: #023047; 
}
h1  {
    font-family: 'Josefin Sans', sans-serif;
    line-height: 1.28571429em;
    margin: calc(2rem - .14285714em) 0 1rem;
    font-weight: 700;
    padding: 0;
}
a {
    color: #3399cc;
    text-decoration: none;
}

.ui.segment {
    font-size: 1.25rem;
    background-color: transparent;
}
.ui.menu {
    background: #8ECAE6;
    font-size: 1.1rem;
    font-weight: 900;
    
}
.sixteen.wide.column.category{
    padding-left: 0px;
    padding-right: 0px;
}
.sixteen.wide.column.algo{
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 0px;
}
.ui.container.grid.algo {
    padding-left: 0px;
    margin-left: 0px;
}


.ui.menu .item{
    color:white;
}
.ui.menu .active.item {
    background: #8ECAE6;
    font-size: 1rem;
    color:white;
    font-weight: bold;
}

.ui.menu.vertical{
    background: #8ECAE6;
    width: auto;
}
.ui.menu.vertical.questionList.dropDown {
    position: absolute;
    z-index: 100;
    border: 1px solid rgba(0, 0, 0, 0.281);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.397);
}
.ui.vertical.menu .active.item {
    background: #fb8500;
    color:rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: none;
}
.ui.segment.question{
    border-radius: 5px;
    margin-top: 0px;
}
.ui.stackable.two.column.grid {
    padding: 0px;
}
.thirteen.wide.two.column.grid.question {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.sixteen.wide.two.column.grid.question {
    padding-top: 0px;
    padding-left: 0px;
}
.three.wide.stackable.two.column.grid {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.ui.segment.code {
    padding: 0px;
    margin: 0px;
}
.five.wide.column.solutions {
    margin-top: 0.5em;
    min-height: 150px;
}
.ui.pointing.menu.algo {
    margin: 0;
    color:white;
}
.row.solutions {
    border-top: 2px solid rgb(33 158 188);
    padding-top: .9em;
    padding-bottom: .5em;
}
.right.menu {
    padding: 0em;
    line-height: .5em;
}
.ui.text.menu{
    margin: 0;
}
.ui.text.menu .header.item {
    color:rgba(255, 255, 255, 0.555);

}
.ui.text.menu .item {
    color:rgba(255, 255, 255, 0.555);
    font-size: .9rem;
    font-weight: 500;
    padding-left: 0em;
    padding-right: 0.7em;
}
.ui.text.menu.solutions {
    margin: 0;
    padding-bottom: 1.3em;   
}
.ui.text.menu .active.item{
    color:rgba(255, 255, 255, 0.753);
    font-weight: 700;
    text-decoration: underline;
}
.questionDisplay {
    margin-bottom: 1em;
}
.codeDisplay pre {
    min-height: 450px;
}
@media only screen and (max-width: 900px) {
    #root {
        border: none;
    }
    .ui.segment.question{
        padding-right: .5em;
    }

}
@media only screen and (max-width: 768px) {
    #root {
        border: none;
    }
    .ui.container.grid{
        margin-left: 2em!important;
    }
    .ui.container.grid.algo{
        clear:both;
        margin-left: 2em!important;
    }
    .ui.vertical.menu.questionList{
        width: auto!important;
        margin-bottom: 3em!important;
        margin-right: 1em;
    }
    .ui.vertical.menu.algo{
        margin-top: 5em!important;
        margin-right: 1em;
        width: auto!important;
    }
    .three.wide.stackable.two.column.grid {
        margin-left: 1em!important;
    }
}


ui.eleven.wide.column {
    margin: 0px;
}

.dropdown {
    position: relative;
    float: right;
    width: 300 px;
    z-index: 100;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.397);
    padding-left: 0px;
  }


.hamburger.icon{
	background-image : url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAlCAYAAADWSWD3AAAACXBIWXMAAAsSAAALEgHS3X78AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAERJREFUeNrs1kENADAIBEGo8gqsJ4oKEpIZBfu7y3pxY5ns6NoWfWIh0aJFAzD98lxTiyhatGgAL8/Ls4iiRYue9AUYAPxdD2pFXaa3AAAAAElFTkSuQmCC);
	background-repeat : no-repeat;
    width: 45px;
    height: 37px;
    object-fit: contain;
}
.ui.grid.mainPart {
    margin-top: 3em;
}

.heading {
    width: 392px;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.half-a-border-on-top {
    /* border-bottom: .5em solid #ffcc00; */
    position: relative;
}
.half-a-border-on-top:after {
    padding:0;margin:0;display:block;/* probably not really needed? */
    content: "";
    height:73.5%;
    width:.5em;/* slight higher to work around rounding errors(?) on some zoom levels in some browsers. */
    background-color: #ffcc00;
    position: absolute;
    right:0;
    top:60%;
}
.half-border-on-bottom:after {
    padding:0;margin:0;display:block;/* probably not really needed? */
    content: "";
    width:50%;
    height:.5em;/* slight higher to work around rounding errors(?) on some zoom levels in some browsers. */
    background-color: #ffcc00;
    position: absolute;
    right:50%;
    bottom:0%;
}

.portraitDisplay {
    display: inline-block;
    position: relative;
    width: 392px;
    height: 390px;
    overflow: hidden;
    border-radius: 50%;
    padding-left: 0;
    padding-right: 0;
    border: #ffcc00 .5em solid;
    box-shadow: 0 0 5px 0px #ffcc00 inset, 0 0 0px 0px rgba(0, 0, 0, 0.418);
    margin-right: auto;
    margin-left:-.60em;
}

.portraitDisplay img {
    width: auto;
    height: 100%;
    margin-right: 30px;
}

.four.column.row.spacing.main {
    height: 300px;
}
.four.column.row.spacing.footer {
    height: 100px;
    
    width: 100%;
}
.four.column.row.spacing.projects {
    height: 100px;
    background: #ffcc00;
    margin-bottom: 2em;
}

.arrowMenu {
    height: 3em;
    position: fixed;
    z-index: 2001;
    display: inline-block;
    /* border-radius: 50%; */
    /* box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.418); */
    /* background-color: #ffcc00; */
    bottom: .5em;
    left: .5em;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
}
/* .arrowMenu img{
    bottom: 10px;
    left: .5em;
    position: fixed;
    box-shadow: 3px 3px 5px 6px #ccc;
} */

/*.arrow-second{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    font-size: 4em;
    text-align: left;
    letter-spacing: -5px;
    bottom: 5%;
    position: fixed;
    z-index: 2001;
    text-shadow: -1px 2px #000000e6;
    transform: rotate(90deg);
    color: #ffcc00;
} */
.arrow-second {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    font-size: 3em;
    letter-spacing: -3px;
    bottom: .65em;
    left: .1em;
    /* left: .5em; */
    position: fixed;
    z-index: 2001;
    text-shadow: -1px 2px #000000;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    color: #ffcc00;
}
/*
    animation: pulse 1s 1;
  }
  
  @keyframes pulse {
    0% {
        bottom: 20%;
    }
    50% {
        bottom: 10%;
    }
    100% {
        bottom: 5%;
    }


.left-arrow:after {
    padding:0;margin:0;display:block;
    content: "";
    height:1080%;
    width:.5em;
    background-color: #ffcc00;
    position: absolute;
    right:0%;
    top:0;
} */
.heading h1{
    font-size: 4em;
    text-align: left;
    text-justify: auto;
    letter-spacing: -2px;
}
.shortH h1 {
    letter-spacing: -4px;
}
.yellowArrow{
    color : #ffcc00;
}

@media only screen and (max-width: 900px) {
    .heading {
        width: 360px;
        margin-left: auto;
        margin-right: auto;
        padding-left: .5em;
    }
    .portraitDisplay {
        margin-top: 3em;
        margin-left: auto;
        margin-right: auto;
    }


    .heading {
        margin-left: auto;
        margin-right: auto;
        padding-left: .5em;
    }
    .portraitDisplay {
        width: 360px;
        height: 365px;
        margin-top: 3em;
        margin-left: auto;
        margin-right: auto;
    }
    .half-border-on-bottom:after {
        height:0;
    }
    .half-a-border-on-top:after {
        width:0;
    }
    .four.column.row.spacing.main {
        height: 100px;
        margin-left: 10px;
        border-left: none;
    }
    
    .four.column.row.spacing.projects {
        height: 50px;
        border-left: none;

    }
    .heading h1{
        font-size: 3.5em;
        text-align: left;
        text-justify: auto;
        letter-spacing: -2px;
    }
}

@media only screen and (max-width: 481px) {
    .portraitDisplay {
        width: 300px;
        height: 305px;
    }
    .heading {
        width: 300px;
    }
    .heading h1{
        font-size: 3em;
    }
}


.ui.grid.inner {
    background-color: #ffcc00;
    margin-left: auto;
    margin-right: auto;
    width: 100%
}
.three.column.stackable.row.projects {
    background-color: #ffcc00;
    margin-top: auto;
    margin-bottom: 50px;
}

.three.column.stackable.row.contactme {
    background-color: #ffcc00;
    margin-top: 100px;
    margin-bottom: 100px;
}

.column.project-menu {
    background-color: #000000;
    height: 60px;
    margin-left: auto;
    
}
.ui.grid>.three.column.row>.column.project-keywords{
    margin-top: 3.75em;
    width: 400px!important;
    margin-left: auto;
}
.ui.grid>.three.column.row>.column.project-keywords.contact{
    margin-top: 0;
    width: 400px!important;
    margin-left: auto;
}
.column.project-keywords {
    background-color: #000000;
    padding-top: 1em;
    
}
.project-keywords h1{
    color: #ffcc00;
    line-height: .9em;
    font-size: 5em;
    text-align: left;
    letter-spacing: -5px;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    padding-left: 0;
}

.ui.grid>.three.column.row>.column.project-contents{
    background-color: #000000;
    width: 480px!important;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
}

.ui.grid>.three.column.row>.column.project-keywords.contact.aboutme>.column.project-content{
    width: 100%!important;
    
}

.column.project-content {
    background-color: #000000;
    width: 480px;
    overflow: hidden;
    padding-left: 1em;
    padding-right: 1em;
    
    
}
.column.project-keywords.contact-hook {
    padding: 1em;
}
.column.project-keywords.contact.aboutme{
    padding-left: 0;
    padding-right: 0;
}

.column.project-content h2{
    color: #ffcc00;
    
}
.column.project-content p{
    font-size: 16px;
    
}
.column.project-content p:last-child {
    margin-bottom: 1.5em;
}
.column.project-keywords p:last-child {
    margin-bottom: 1.5em;
}

.ui.tabular.menu.latest {
    background-color: #ffcc00;
    border-radius: 0px;
    box-shadow: none!important;
    border-bottom: .55em solid #ffcc00;
}
.ui.tabular.menu.latest>.active.item {
    background-color: #000000;
    color: #ffcc00;
    font-size: 1.1rem;
}
.ui.tabular.menu.latest .item {
    border-bottom: none;
    border: none;
    padding: .92857143em 1.42857143em;
    color: rgba(0,0,0,.87);
}


/* .two.column.row.content{ */
.ui.internally.celled.grid{
	height: 80vh;
	background-color: #ffcc00;
    margin-top:auto;
	margin-bottom:10px;
}

.column.project.section1 h1{

    color: #000000;
    line-height: .9em;
    font-size: 5em;
    text-align: left;
    letter-spacing: -5px;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    padding-left: 0;
}
/* // Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... } */

@media only screen and (max-width: 920px) {
    .project-keywords h1{
        font-size: 3em;
        letter-spacing: -6px;
    }
    
    .ui.grid>.three.column.row>.column.project-keywords{
        width: 240px!important;
    }
    .ui.grid>.three.column.row>.column.project-keywords.contact{
        width: 100%!important;

    }
    .ui.grid>.three.column.row>.column.project-contents.contact{
        width: 100%!important;

    }

}@media only screen and (max-width: 768px) {
    .ui.grid {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .project-keywords h1{
        font-size: 4em;
        letter-spacing: 0px;
    }
    .ui.grid>.three.column.row>.column.project-keywords{
        width: 100%!important;
    }

    .ui.grid>.three.column.row>.column.project-contents{
        width: 100%!important;
    }

    .ui.grid>.stackable.stackable.row>.column, 
    .ui.stackable.grid>.column.grid>.column, 
    .ui.stackable.grid>.column.row>.column, 
    .ui.stackable.grid>.column:not(.row), 
    .ui.stackable.grid>.row>.column, 
    .ui.stackable.grid>.row>.wide.column, 
    .ui.stackable.grid>.wide.column {
        padding: 1rem 1rem!important;
    }
    .three.column.stackable.row.projects {
        margin-bottom: auto;
    }
    
    .three.column.stackable.row.contactme {
        margin-top: auto;
        margin-bottom: auto;
    }
    .column.project-content{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        
    }
    .column.project-keywords.contact-hook {
        padding: 0;
    }


    .ui.tabular.menu.latest{
       
        width: auto;
        display: block;
        -webkit-flex-direction: column;
                flex-direction: column;
        background: rgb(0, 0, 0);
        /* -webkit-box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
        box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%); */
        border: none;
        text-align: center;
        margin-bottom: 2em;

    }
    .ui.tabular.menu.latest .item {
        margin-top: .3em;
        margin-left: .3em;
        margin-right: .3em;
        background: #947705;
        display: block;
        border: none;
        padding: 0 1em 0 0em;
        color: rgb(0, 0, 0); 
        font-size: 1.5em;
        line-height: 1.5em;
        margin-bottom: .3em;
    }

    .ui.tabular.menu.latest .active.item {
        margin-top: .3em;
        background: #ffcc00;
        font-size: 1.5em;
        line-height: 1.7em;
        margin-bottom: .3em;
        color: rgb(0, 0, 0); 
    } 

    p {
        font-size: 1.25rem;
        text-align: justify;
    }

    
    
}

#footer {
    width: 100%;
    background-color: #ffcc00;
}
.ui.grid.footer {
    width: 100%;
    height: 100%;
    padding: 1em;
    margin-right: 10px;
    background-color: #ffcc00;
}

@media only screen and (max-width: 768px) {
    .four.column.row.spacing.footer {
        border-left: none;
        height: 50px;
    }
}


input, textarea {
	font-family:inherit;
	font-size: inherit;
}
input[type=text],input[type=email] {
	width:100%;
	background: #ffefb1;
	padding: 1em;
	margin: 1em 0em;
	border: 1px solid rgba(34,36,38,.15);
	box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
	border-radius:10px;
}
textarea {
	background: #ffefb1;
	width:100%;
	resize: vertical;
	padding:1em;
	margin: 1em 0em;;
	border-radius:15px;
	border: 1px solid rgba(34,36,38,.15);
	box-shadow:4px 4px 10px rgba(0,0,0,0.06);
	height:150px;
	transition: .3s box-shadow;
}
label {
	position: relative; /* to contain absolute elements */
	padding-right:5em;
	cursor: pointer;
}

.contact button {

	/* remove default behavior */
	appearance:none;
	-webkit-appearance:none;
	/* usual styles */
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.2em;
    font-weight: 700;
    z-index: 101;
    padding: .5em .5em;
    color: #fffffff0;
    background: #ffcc00;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    float: right;
	margin-bottom: 1em;
	
  }
.column.contact h3{
	position: relative;
    padding-left: .8em;
    color: #ffcc00;
    font-family: inherit;
    padding-top: 1em;
}

.error-message {
	font-family: inherit;
	font-size: inherit;
	/* box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
	border: 1px solid rgba(34,36,38,.15);
	border-radius: .28571429rem;
	background: #f8f8f9; */
    color: #fb8500;
	position: relative;
	width: 90%;
    margin: .1em;
    padding: 1em;
    line-height: 1.4285em;
}

.submit-feedback {
	font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4em;
	color: #fb8500;
	background: #000000;
}

.ui.grid.headerPart {
    width: 100%;
    padding: 1em;
}
.logoLink {
    padding-top: .3em;

}
.logoLink h1{
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    font-size: 4em;
    text-align: left;
    letter-spacing: -5px;
}
.logoLink .name-logo{
    color: #3399cc;
}
.logoLink .name-short-logo{
    color: #3399cc;
    display: none;
}
.logoLink .domain-logo{
    color:#ffcc00;
}
.burgerMenu {
    position: fixed;
    z-index: 150;
    margin: 2em 2em;
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
    background-color: rgba(0, 0, 0, 0.877);
    border: 1px solid rgb(0, 0, 0, 0.877);
}

.contactButton {
    height: 2.5em;
    top: .7em;
    right: 4em;
    margin-bottom: 1.2em; 
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1.2em;
    z-index: 101;
    padding: 0em 1em;
    color: #fffffff0;
    background: #ffcc00;
    border: 1px solid rgb(34, 36, 38);
    border-radius: .38571429rem;
    box-shadow: 0 2px 2px 0 rgb(34 36 38 / 15%);
    position: relative;
}

.contactButton a {
    color: inherit;
}
.contactButton a:hover {
    color: #3399cc;
}
.nav-bar {
    position: fixed;
    z-index: 101;
    top: 1.3em;
    right: 1.4em;
    left: auto;
    bottom: auto;


    background: #000000;
    width: auto;
    font-size: 1.3rem;
    line-height: 1.6em;
    font-weight: 700;

    font-family: inherit;
    font-weight: 700;
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    border-radius: .28571429rem;
    min-height: 2.85714286em;
}
.nav-bar ul {
    background: #000000;
    list-style-type: none;
    padding: 0;
}

.nav-bar ul li {
    /* background: #3399cc; */
    padding-left: .5em;
    padding-right: 1em;
    margin: 15px 15px 5px 5px;
}
.hide-arrow{
    display: none;
}
a:hover .hide-arrow{
    display: inline;
}
a:hover .space{
    display: hide;
}
@media only screen and (max-width: 768px) {
    #root {
        border: none;
    }
    .logoLink h1{
        
        font-size: 2.5em;
        text-align: left;
        letter-spacing: -2px;
    }
    .contactButton {
        top: .9em;
        font-size: inherit;
    }
}
@media only screen and (max-width: 481px) {
    .logoLink .name-logo{
        display: none;
    }
    .logoLink .name-short-logo{
        display: inline;
    }
}
