

.ui.grid.inner {
    background-color: #ffcc00;
    margin-left: auto;
    margin-right: auto;
    width: 100%
}
.three.column.stackable.row.projects {
    background-color: #ffcc00;
    margin-top: auto;
    margin-bottom: 50px;
}

.three.column.stackable.row.contactme {
    background-color: #ffcc00;
    margin-top: 100px;
    margin-bottom: 100px;
}

.column.project-menu {
    background-color: #000000;
    height: 60px;
    margin-left: auto;
    
}
.ui.grid>.three.column.row>.column.project-keywords{
    margin-top: 3.75em;
    width: 400px!important;
    margin-left: auto;
}
.ui.grid>.three.column.row>.column.project-keywords.contact{
    margin-top: 0;
    width: 400px!important;
    margin-left: auto;
}
.column.project-keywords {
    background-color: #000000;
    padding-top: 1em;
    
}
.project-keywords h1{
    color: #ffcc00;
    line-height: .9em;
    font-size: 5em;
    text-align: left;
    letter-spacing: -5px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-left: 0;
}

.ui.grid>.three.column.row>.column.project-contents{
    background-color: #000000;
    width: 480px!important;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
}

.ui.grid>.three.column.row>.column.project-keywords.contact.aboutme>.column.project-content{
    width: 100%!important;
    
}

.column.project-content {
    background-color: #000000;
    width: 480px;
    overflow: hidden;
    padding-left: 1em;
    padding-right: 1em;
    
    
}
.column.project-keywords.contact-hook {
    padding: 1em;
}
.column.project-keywords.contact.aboutme{
    padding-left: 0;
    padding-right: 0;
}

.column.project-content h2{
    color: #ffcc00;
    
}
.column.project-content p{
    font-size: 16px;
    
}
.column.project-content p:last-child {
    margin-bottom: 1.5em;
}
.column.project-keywords p:last-child {
    margin-bottom: 1.5em;
}

.ui.tabular.menu.latest {
    background-color: #ffcc00;
    border-radius: 0px;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    border-bottom: .55em solid #ffcc00;
}
.ui.tabular.menu.latest>.active.item {
    background-color: #000000;
    color: #ffcc00;
    font-size: 1.1rem;
}
.ui.tabular.menu.latest .item {
    border-bottom: none;
    border: none;
    padding: .92857143em 1.42857143em;
    color: rgba(0,0,0,.87);
}


/* .two.column.row.content{ */
.ui.internally.celled.grid{
	height: 80vh;
	background-color: #ffcc00;
    margin-top:auto;
	margin-bottom:10px;
}

.column.project.section1 h1{

    color: #000000;
    line-height: .9em;
    font-size: 5em;
    text-align: left;
    letter-spacing: -5px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-left: 0;
}
/* // Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... } */

@media only screen and (max-width: 920px) {
    .project-keywords h1{
        font-size: 3em;
        letter-spacing: -6px;
    }
    
    .ui.grid>.three.column.row>.column.project-keywords{
        width: 240px!important;
    }
    .ui.grid>.three.column.row>.column.project-keywords.contact{
        width: 100%!important;

    }
    .ui.grid>.three.column.row>.column.project-contents.contact{
        width: 100%!important;

    }

}@media only screen and (max-width: 768px) {
    .ui.grid {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .project-keywords h1{
        font-size: 4em;
        letter-spacing: 0px;
    }
    .ui.grid>.three.column.row>.column.project-keywords{
        width: 100%!important;
    }

    .ui.grid>.three.column.row>.column.project-contents{
        width: 100%!important;
    }

    .ui.grid>.stackable.stackable.row>.column, 
    .ui.stackable.grid>.column.grid>.column, 
    .ui.stackable.grid>.column.row>.column, 
    .ui.stackable.grid>.column:not(.row), 
    .ui.stackable.grid>.row>.column, 
    .ui.stackable.grid>.row>.wide.column, 
    .ui.stackable.grid>.wide.column {
        padding: 1rem 1rem!important;
    }
    .three.column.stackable.row.projects {
        margin-bottom: auto;
    }
    
    .three.column.stackable.row.contactme {
        margin-top: auto;
        margin-bottom: auto;
    }
    .column.project-content{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        
    }
    .column.project-keywords.contact-hook {
        padding: 0;
    }


    .ui.tabular.menu.latest{
       
        width: auto;
        display: block;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background: rgb(0, 0, 0);
        /* -webkit-box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
        box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%); */
        border: none;
        text-align: center;
        margin-bottom: 2em;

    }
    .ui.tabular.menu.latest .item {
        margin-top: .3em;
        margin-left: .3em;
        margin-right: .3em;
        background: #947705;
        display: block;
        border: none;
        padding: 0 1em 0 0em;
        color: rgb(0, 0, 0); 
        font-size: 1.5em;
        line-height: 1.5em;
        margin-bottom: .3em;
    }

    .ui.tabular.menu.latest .active.item {
        margin-top: .3em;
        background: #ffcc00;
        font-size: 1.5em;
        line-height: 1.7em;
        margin-bottom: .3em;
        color: rgb(0, 0, 0); 
    } 

    p {
        font-size: 1.25rem;
        text-align: justify;
    }

    
    
}
