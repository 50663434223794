#footer {
    width: 100%;
    background-color: #ffcc00;
}
.ui.grid.footer {
    width: 100%;
    height: 100%;
    padding: 1em;
    margin-right: 10px;
    background-color: #ffcc00;
}

@media only screen and (max-width: 768px) {
    .four.column.row.spacing.footer {
        border-left: none;
        height: 50px;
    }
}
